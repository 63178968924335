/* eslint-disable react/forbid-prop-types */
import React from "react";
import Layout from "@/Layout";
import { graphql } from "gatsby";

const Page = ({ data, location }) => {
  const { wpPage: page } = data;
  return (
    <Layout seo={page.seo} location={location}>
      <div>Hello, page {page.title}!</div>
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      ...SEO
    }
  }
`;
